import axios from 'axios';
import swal from 'sweetalert';
import { store } from '../redux';
import { launchFromImageRequest } from '../redux/goLiveRedux';
import CryptoJS from 'crypto-js';

const headers = {
  'Content-Type': 'application/json',
  'x-requested-with': 'XMLHttpRequest',
};

const { REACT_APP_API_BASE_URL } = process.env;

axios.defaults.baseURL = REACT_APP_API_BASE_URL;
axios.defaults.headers = headers;
axios.defaults.timeout = 120000;

function call(method, URL, params, data = {}, responseType = 'json') {
  const response = axios.request({
    method,
    url: URL,
    params,
    data,
    responseType,
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        const isPandoErpIntegration = !!axios.defaults.headers['x-pando-is-erp-integration'];

        delete axios.defaults.headers.Authorization;
        delete axios.defaults.headers['x-pando-studio-id'];
        delete axios.defaults.headers['x-pando-is-admin-app'];
        delete axios.defaults.headers['x-pando-is-erp-integration'];
        localStorage.removeItem('activeStudio');
        localStorage.removeItem('token');
        if (isPandoErpIntegration) {
          window.location = '/reports';
        } else {
          window.location = '/login';
        }
      } else {
        return Promise.reject(error);
      }
    },
  );

  response.catch((error) => {
    if (error && error.response) {
      const { data, status, config } = error.response;
      if (status !== 401 && data.message) {
        if (
          data.message.includes("Insufficient capacity") ||
          data.message.includes(
            "The requested configuration is currently not supported"
          )
        ) {
          swal({
            title: "Unable to launch instance",
            text: "AWS is unable to launch this instance with the selected configuration.\n\nTry to change the instance type first. This will impact compute capacity but is instantaneous.\n\nAlternatively, you can change the availability zone, which would launch it from an AMI taking much longer.",
            buttons: {
              cancel: "Close",
            },
          });
        } else if (!data.message.includes('jwt')) {
          swal({
            text: data.message,
            title: 'Error',
          });
        }
      }
    }
  });
  return response;
}

export function setToken(token) {
  axios.defaults.headers.Authorization = `bearer ${token}`;
  axios.defaults.headers['x-pando-is-admin-app'] = true;
}

export function setErpIntegrationHeader() {
  axios.defaults.headers['x-pando-is-erp-integration'] = 'true';
}

export function setStudioIdHeader(studio) {
  axios.defaults.headers['x-pando-studio-id'] = `${studio}`;
}

export function setEventSettingsAuthKeyHeader(key) {
  axios.defaults.headers['x-pando-event-settings-key'] = `${key}`;
}

export function get(URL, params, responseType) {
  return call('GET', URL, params, null, responseType);
}

export function post(URL, data) {
  return call('POST', URL, null, data);
}

export function put(URL, data) {
  return call('PUT', URL, null, data);
}

export function del(URL) {
  return call('DELETE', URL);
}

export function postFormData(URL, formData) {
  const config = {
    onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.post(`${REACT_APP_API_BASE_URL}${URL}`, formData, config);
}

export function putFormData(URL, formData) {
  const config = {
    onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.put(`${REACT_APP_API_BASE_URL}${URL}`, formData, config);
}

export async function uploadToS3(file, fileName, fileType, event, fileKey, progressCallback) {
  return new Promise(async (resolve, reject) => {
    try {
      const reader = new FileReader();
      const fileReaderPromise = new Promise((resolve) => {
        reader.onload = () => {
          const hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(reader.result));
          resolve(hash.toString(CryptoJS.enc.Hex));
        };
      });
      reader.readAsBinaryString(file);

      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/file/upload_to_s3`,
        { fileName, fileType, event, fileKey },
        { headers: { ...axios.defaults.headers } },
      );
      const returnData = response.data;
      const { signedRequest } = returnData;
      const options = {
        onUploadProgress: progressCallback,
        headers: {
          'Content-Type': fileType,
        },
        timeout: 0,
      };

      const axiosInstance = axios.create({
        headers: {
          'Content-Type': fileType,
        },
      });
      axiosInstance.defaults.timeout = 0;
      axiosInstance.interceptors.request.use((config) => {
        delete config.headers.Authorization;
        delete config.headers['x-pando-studio-id'];
        delete config.headers['x-pando-is-admin-app'];
        return config;
      });
      await axiosInstance.put(signedRequest, file, options);
      const md5 = await fileReaderPromise;

      delete returnData.signedRequest;
      resolve({ ...returnData, size: file.size, metadata: { ...file.metadata, hash: md5 } });
    } catch (error) {
      reject(error);
    }
  });
}

export function getQRCode(URL, data) {
  return call('POST', URL, null, data, 'arraybuffer');
}
